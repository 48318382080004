<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Documents</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "add_document.svg",
          title: "Add Document",
          url: "my-documents/add-document",
        },
        {
          img: "vet_reports.svg",
          title: "Vet Reports",
        },
        {
          img: "my_permits.svg",
          title: "My Permits",
        },
        {
          img: "government_applications.svg",
          title: "Government Applications",
        },
        {
          img: "deeds_and_licenses.svg",
          title: "Deeds and Licenses",
        },
        {
          img: "analysis_reports.svg",
          title: "Analysis Reports",
        },
        {
          img: "weather_reports.svg",
          title: "Weather Reports",
        },
        {
          img: "saved_newsletters_and_info_sheets.svg",
          title: "Saved Newsletters and Info Sheets",
        },
        {
          img: "satellite_reports.svg",
          title: "Satellite Reports",
        },
        {
          img: "history.svg",
          title: "History",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/my_documents/" + img;
    },
  },
};
</script>
